import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export type DATE_FORMATS =
  | 'MM/DD/YY'
  | 'MM/DD/YYYY'
  | 'MMM DD'
  | 'MMMM DD'
  | 'MMMM YYYY'
  | 'MMM DD, YYYY'
  | 'MM/DD/YYYY [at] hh:mmA'
  | 'YYYY MM DD'
  | string;

export const formatDate = (
  date: dayjs.ConfigType,
  format: DATE_FORMATS = 'MM/DD/YYYY',
): string => {
  return date ? dayjs(date).format(format) : 'TBD';
};

export const formatUtcDate = (
  date: dayjs.ConfigType,
  format: DATE_FORMATS = 'MM/DD/YYYY',
): string => {
  return date ? dayjs(date).utc().format(format) : 'TBD';
};

export const addYears = (date: Date, years: number): Date => {
  return new Date(date.setFullYear(date.getFullYear() + years));
};
