import { formatDate, formatUtcDate } from 'utils/date';

export const formatCurrency = (value: number, currencySymbol: string = '$') => {
  const symbol = value < 0 ? '-' : '';
  return `${symbol}${currencySymbol}${
    Math.round((Math.abs(value) / 100 + Number.EPSILON) * 100) / 100
  }`;
};

export const convertECurrencyToCurrency = (value: number) => {
  return Math.round(((value || 0) / 100 + Number.EPSILON) * 100) / 100;
};

export const formatPhone = str => {
  //Filter only numbers from the input
  let cleaned = ('' + str).replace(/\D/g, '');

  //Check if the input is of correct
  let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    //Remove the matched extension code
    //Change this to format for any country code.
    let intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }

  return null;
};

type FormatType = 'date' | 'utc-date' | 'currency';

export const format = (value: any, type: FormatType): string => {
  switch (type) {
    case 'currency':
      return formatCurrency(value);
    case 'date':
      return formatDate(value);
    case 'utc-date':
      return formatUtcDate(value);
    default:
      return value;
  }
};
