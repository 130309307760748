import { useEffect } from 'react';
import { useLayout } from 'context/layout.context';

export const useLockHeaderVisible = () => {
  const { lockHeaderVisible, unlockHeaderVisible } = useLayout();

  useEffect(() => {
    lockHeaderVisible();

    return () => {
      unlockHeaderVisible();
    };
  }, []);
};
