import { useEffect } from 'react';

export const useViewportMeasures = () => {
  useEffect(() => {
    let observer;

    if (process.browser) {
      // @ts-ignore
      observer = new ResizeObserver(() => {
        document.documentElement.style.setProperty(
          '--vw',
          `${document.documentElement.clientWidth}px`,
        );

        document.documentElement.style.setProperty(
          '--vh',
          `${document.documentElement.clientHeight}px`,
        );
      });

      observer.observe(document.documentElement);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  });
};
