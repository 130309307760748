import { useEffect, useRef } from 'react';
import { debounce } from 'lodash';

export const useIntersectionObserver = ({
  onIntersect,
  root = null,
  threshold = 1.0,
  rootMargin = '0px',
  debounceTime = 100,
  triggerOnce = false,
}) => {
  const observerRef = useRef();

  useEffect(() => {
    const currentTarget = observerRef.current;

    const observer = new IntersectionObserver(
      debounce(event => {
        onIntersect(event);

        if (triggerOnce && event && event.length && event[0].isIntersecting) {
          observer.unobserve(currentTarget);
        }
      }, debounceTime),
      {
        root: root?.current,
        rootMargin,
        threshold,
      },
    );

    if (!observerRef) {
      console.warn(
        `No target specified for Intersection Observer.
                    Please add a target option and try again.
                    More info: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API#Creating_an_intersection_observer`,
      );
      return;
    }

    observer.observe(currentTarget);

    return () => {
      observer.unobserve(currentTarget);
    };
  }, []);

  return { observerRef };
};
