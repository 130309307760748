import { useEffect, useState } from 'react';

export const useArSupported = () => {
  const [supported, setSupported] = useState(false);

  useEffect(() => {
    const a = document.createElement('a');

    if (a.relList.supports('ar')) {
      setSupported(true);
    }
  }, []);

  return supported;
};
