import { Address } from 'types/user.type';
import { PaymentRequestShippingAddress, Token } from '@stripe/stripe-js';
import { CartRecord, CartSummary } from 'context/cart.context';
import { CartProduct } from 'services/api/cart/cart-api.types';
import { convertECurrencyToCurrency } from 'utils/formaters';
import { GiftCardRecord } from 'types/gift-card.type';

export const convertPaymentRequestAddressToUserAddress = (
  shippingAddress: PaymentRequestShippingAddress,
): Address => {
  return shippingAddress
    ? {
        street:
          shippingAddress.addressLine &&
          shippingAddress.addressLine.length &&
          shippingAddress.addressLine[0],
        city: shippingAddress.city,
        state: shippingAddress.region,
        zip: shippingAddress.postalCode,
        country: shippingAddress.country,
        phone: shippingAddress.phone,
      }
    : null;
};

export const extractCardInfoFromToken = (token: Token) => ({
  cardType: token.card.brand,
  expiration: `${token.card.exp_month}${token.card.exp_year}`,
  number: token.card.last4,
  billingZipCode: token.card.address_zip,
  prefer: false,
});

export const extractNamePartsFromName = (
  fullName: string,
): { firstName: string; lastName: string } => {
  const names = String(fullName).split(' ');
  const firstName = names.length && names[0];
  const lastName = names.length > 1 ? names.splice(1).join(' ') : '';

  return { firstName, lastName };
};

export const convertCartToAnalyticsCartViewEvent = (
  summary: CartSummary,
  records: CartProduct[],
) => ({
  coupon: summary.giftCode || (summary.credit ? 'CREDIT' : ''),
  currency: 'USD',
  discount: convertECurrencyToCurrency(
    Math.min(summary.giftDiscount || summary.credit, summary.subtotal),
  ),
  products: records.map(record => ({
    brand: record.designerName,
    category: record?.product?.category,
    currency: 'USD',
    name: record?.product?.name,
    price: convertECurrencyToCurrency(record.price),
    product_id: record.sku,
    quantity: 1,
    sku: record.sku,
  })),
  revenue: convertECurrencyToCurrency(summary.subtotal),
  shipping: convertECurrencyToCurrency(summary.shipping),
  subtotal: convertECurrencyToCurrency(summary.total),
  tax: convertECurrencyToCurrency(summary.tax),
  value: convertECurrencyToCurrency(summary.total),
});

export const convertCartToAnalyticsOrderCompletedEvent = (
  summary: CartSummary,
  records: CartProduct[],
  orderId,
) => ({
  order_id: orderId,
  coupon: summary.giftCode || (summary.credit ? 'CREDIT' : ''),
  currency: 'USD',
  discount: convertECurrencyToCurrency(
    Math.min(summary.giftDiscount || summary.credit, summary.subtotal),
  ),
  products: records.map(record => ({
    brand: record.designerName,
    category: record?.product?.category,
    currency: 'USD',
    name: record?.product?.name,
    price: convertECurrencyToCurrency(record.price),
    product_id: record.sku,
    quantity: 1,
    sku: record.sku,
  })),
  revenue: convertECurrencyToCurrency(summary.subtotal),
  shipping: convertECurrencyToCurrency(summary.shipping),
  subtotal: convertECurrencyToCurrency(summary.total),
  tax: convertECurrencyToCurrency(summary.tax),
  value: convertECurrencyToCurrency(summary.total),
});

export const recalculateInStock = (
  records: CartRecord[],
  product: CartRecord,
): CartRecord[] => {
  let stocksCounter = product.product.stocks;

  return records.map((item: CartRecord) => {
    if ((!item.type || item.type === 'product') && item.sku === product.sku) {
      stocksCounter--;

      return Object.assign({}, item, { inStock: stocksCounter >= 0 });
    }

    return item;
  });
};

export const extractAnalyticsGiftCardDataFromRecord = (
  record: GiftCardRecord,
) => {
  if (!record) {
    return {};
  }

  return {
    brand: 'Gantri',
    category: 'Gift Card',
    currency: 'USD',
    name: record.design?.title,
    price: record.amount,
    product_id: record.productId,
    quantity: 1,
  };
};
