import { StackedContainerProps } from './stacked-container.props';

export const StackedContainerPresets: Partial<StackedContainerProps> = {
  gap: 's1',
  justifyContent: 'unset',
  alignContent: 'start',
  padding: 'unset',
  horizontalPadding: { lg: 's3', sm: 's1' },
  flow: 'row',
  height: '100%',
};
