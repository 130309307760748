import { useEffect, useState } from 'react';

export const useCurrentUrl = (): string => {
  const [link, setLink] = useState('');

  useEffect(() => {
    setLink(window.location.href);
  }, []);

  return link;
};
