import { useCallback, useEffect, useState } from 'react';
import { initializeAffirm } from 'src/third-party/affirm';
import { CheckoutShipping } from 'modules/shop/checkout/checkout.types';
import { useAuth } from 'context/auth.context';
import { useCart } from 'context/cart.context';
import { useTimeoutFn } from 'react-use';
import { EXTERNAL_SCRIPTS_FETCH_TIME } from 'constants/common';
import { extractNamePartsFromName } from 'modules/shop/cart/cart.adapters';

const gantriAddress = {
  line1: '170 Capp Street',
  city: 'San Francisco',
  state: 'California',
  country: 'US',
  zipcode: 94110,
};

interface AffirmInitialData {
  apr?: string;
  months?: number;
  amount?: number;
  loadImmediately?: boolean;
}

interface AffirmCheckoutResponse {
  checkout_token: string;
  reason: string;
}

export const useAffirm = (initial?: AffirmInitialData) => {
  const [loaded, setLoaded] = useState(false);
  const [ready, setReady] = useState(false);
  const [monthlyEstimate, setMonthlyEstimate] = useState('');
  const [{ authenticated, user }] = useAuth();
  const [{ records, cartSummary }] = useCart();

  const [, cancel] = useTimeoutFn(
    () => {
      initializeAffirm();

      window.affirm.jsReady(() => {
        setLoaded(true);
      });
      cancel();
    },
    initial.loadImmediately ? 0 : EXTERNAL_SCRIPTS_FETCH_TIME,
  );

  useEffect(() => {
    if (!ready && !!window?.affirm?.ui) {
      window.affirm.ui.ready(() => {
        setReady(true);
      });
    }
  }, [loaded]);

  const refresh = useCallback(() => {
    if (window?.affirm?.ui?.refresh) {
      window.affirm.ui.refresh();
    }
  }, [ready]);

  useEffect(() => {
    if (
      ready &&
      initial?.amount &&
      initial?.amount >= 5000 &&
      initial?.amount <= 1750000
    ) {
      window.affirm.ui.payments?.get_estimate(initial, estimate => {
        setMonthlyEstimate(estimate.payment_string);

        refresh();
      });
    }
  }, [ready, initial?.amount, initial?.months]);

  const checkout = useCallback(
    (
      shipping: CheckoutShipping,
      billing: CheckoutShipping,
    ): Promise<AffirmCheckoutResponse> => {
      const address = shipping || billing;
      const { firstName, lastName } = extractNamePartsFromName(address.name);

      const shippingAddress = {
        name: {
          first: address.firstName || firstName,
          last: address.lastName || lastName,
        },
        address: address.streetAddress
          ? {
              line1: address.streetAddress,
              city: address.city,
              state: address.state,
              country: address.country,
              zipcode: address.zip,
            }
          : gantriAddress,
        email: address.email,
      };

      const billingAddress = {
        name: billing.name,
        address: billing.streetAddress
          ? {
              line1: billing.streetAddress,
              city: billing.city,
              state: billing.state,
              country: billing.country,
              zipcode: billing.zip,
            }
          : gantriAddress,
        email: billing.email,
      };

      const items = records.map(record =>
        record.type === 'gift-card'
          ? {
              sku: record.productId,
              display_name: record.design.title,
              unit_price: record.amount,
            }
          : {
              sku: record.sku,
              display_name: record.fullSkuName,
              unit_price: record.price,
            },
      );

      window.affirm.checkout({
        merchant: {
          user_cancel_url: window.location.href,
          user_confirmation_url: window.location.href,
          user_confirmation_url_action: 'GET',
          merchant: 'Gantri',
        },
        shipping: shippingAddress,
        billing: billingAddress,
        metadata: {
          mode: 'modal',
        },
        items,
        shipping_amount: cartSummary.shipping,
        tax_amount: cartSummary.tax ?? 0,
        total: cartSummary.total,
      } as any);

      return new Promise((resolve, reject) => {
        window.affirm.checkout.open({
          onSuccess: response => {
            resolve(response);
          },
          onFail: response => {
            reject({
              ...response,
              error: true,
            });
          },
        });
      });
    },
    [cartSummary],
  );

  return { affirmAvailable: ready, monthlyEstimate, refresh, checkout };
};
