import React, { createContext, Dispatch, useContext, useEffect } from 'react';
import { useImmerReducer } from 'use-immer';
import { ShippingLeadTime } from 'types/shipping.type';
import {
  CartProduct,
  ShipmentType,
  ShippingMethod,
} from 'services/api/cart/cart-api.types';
import sumBy from 'lodash/sumBy';
import { GiftCode } from 'types/user.type';
import { GiftCardRecord } from 'types/gift-card.type';

export interface CartSummary {
  credit: number;
  giftDiscount: number;
  giftCode: string;
  shipping: number;
  subtotal: number;
  giftCardTotal: number;
  tax: number;
  total: number;
}

export type CartRecord = CartProduct &
  GiftCardRecord & {
    type: 'product' | 'gift-card';
  };

export type CartStatus = 'UN_SYNCED' | 'SYNCING' | 'SYNCED' | 'SYNC_FAILED';

export interface CartState {
  records?: CartRecord[];
  recordsInStock?: CartProduct[];
  recordsNotInStock?: CartProduct[];
  giftCards?: GiftCardRecord[];
  wishlist?: CartProduct[];
  count?: number;
  leadTime?: ShippingLeadTime;
  initialized?: boolean;
  tax?: number;
  credit?: number;
  giftCode?: GiftCode;
  cartSummary?: CartSummary;
  shippingMethods?: ShippingMethod[];
  shipmentMethod?: ShippingMethod;
  status: CartStatus;
}

const initialState: CartState = {
  records: [],
  wishlist: [],
  count: 0,
  leadTime: {},
  initialized: false,
  shippingMethods: [],
  shipmentMethod: null,
  status: 'UN_SYNCED',
};

type Action =
  | { type: 'SET_PROPERTIES'; data: Partial<CartState> }
  | { type: 'SET_STATUS'; value: CartStatus }
  | { type: 'SET_TAX'; value: number }
  | { type: 'SET_CREDIT'; value: number }
  | { type: 'SET_GIFT_CODE'; code: GiftCode }
  | { type: 'SET_CART_RECORDS'; records: CartRecord[] }
  | { type: 'ADD_TO_CART'; data: CartRecord }
  | { type: 'UPDATE_WISHLIST'; wishlist: CartProduct[] }
  | { type: 'UPDATE_SHIPMENT_METHOD'; shipmentType: ShipmentType };

function cartReducer(draft: CartState, action: Action) {
  switch (action.type) {
    case 'SET_PROPERTIES':
      draft = Object.assign({}, draft, action.data);
      return draft;
    case 'SET_STATUS':
      draft.status = action.value;
      return draft;
    case 'SET_TAX':
      draft.tax = action.value;
      return draft;
    case 'SET_CREDIT':
      draft.credit = action.value;
      return draft;
    case 'SET_GIFT_CODE':
      draft.giftCode = action.code;
      return draft;
    case 'ADD_TO_CART':
      draft.records.push(action.data);
      return draft;
    case 'UPDATE_WISHLIST':
      draft.wishlist = action.wishlist;
      return draft;
    case 'SET_CART_RECORDS':
      draft.records = action.records;
      return draft;
    case 'UPDATE_SHIPMENT_METHOD':
      draft.shipmentMethod = draft.shippingMethods.find(
        record => record.type === action.shipmentType,
      );
      return draft;
  }
}

const CartStateContext = createContext<CartState>(initialState);

const CartDispatchContext = createContext<Dispatch<Action> | undefined>(
  undefined,
);

export const CartProvider = (props: {
  value?: CartState;
  children: React.ReactNode;
}) => {
  const { value, children } = props;

  const [state, dispatch] = useImmerReducer(
    cartReducer,
    Object.assign({}, initialState, value),
  );

  useEffect(() => {
    dispatch({ type: 'SET_PROPERTIES', data: value });
  }, [value]);

  const { recordsInStock, recordsNotInStock, giftCards } = state.records.reduce(
    (accumulator: any, current: CartRecord, index: number) => {
      let recordsInStock = accumulator.recordsInStock;
      let recordsNotInStock = accumulator.recordsNotInStock;
      let giftCards = accumulator.giftCards;

      if (current.type === 'gift-card') {
        giftCards = giftCards.concat(current);
      } else {
        const temp = [Object.assign({}, current, { originalPosition: index })];

        if ((current as CartProduct).inStock) {
          recordsInStock = recordsInStock.concat(temp);
        } else {
          recordsNotInStock = recordsNotInStock.concat(temp);
        }
      }

      return {
        recordsInStock,
        recordsNotInStock,
        giftCards,
      };
    },
    {
      recordsInStock: [],
      recordsNotInStock: [],
      giftCards: [],
    },
  );

  const giftCardTotal = sumBy(giftCards, 'amount') ?? 0;
  const subtotal = (sumBy(state.records, 'price') ?? 0) + giftCardTotal;
  const shipping = state.records.length * state.shipmentMethod?.cost || 0;
  const giftDiscount =
    state.giftCode?.type === 'Discount %'
      ? (state.giftCode?.amount / 100) * subtotal
      : state.giftCode?.amount ?? 0;
  const credit = !giftDiscount ? state.credit ?? 0 : 0;

  const total = subtotal + shipping + (state.tax ?? 0) - credit - giftDiscount;

  const cartState = Object.assign(
    {
      recordsInStock,
      recordsNotInStock,
      giftCards,
      count: state.records.length,
      cartSummary: {
        subtotal,
        tax: state.tax,
        giftDiscount,
        giftCode: state.giftCode?.code || '',
        giftCardTotal,
        credit,
        shipping,
        total: Math.max(total, 0),
      },
    } as CartState,
    state,
  );

  return (
    <CartStateContext.Provider value={cartState}>
      <CartDispatchContext.Provider value={dispatch}>
        {children}
      </CartDispatchContext.Provider>
    </CartStateContext.Provider>
  );
};

export function useCartState(): CartState {
  const context = useContext(CartStateContext);

  if (context === undefined) {
    throw new Error(`useCart must be used inside an CartProvider`);
  }

  return context;
}

export function useCartDispatch() {
  const context = useContext(CartDispatchContext);

  if (context === undefined) {
    throw new Error(`useCart must be used inside an CartProvider`);
  }

  return context;
}

export const useCart = (): [CartState, Dispatch<Action>] => [
  useCartState(),
  useCartDispatch(),
];
