import React, { createContext, Dispatch, useContext } from 'react';
import { useImmerReducer } from 'use-immer';
import { Address, Payment, User } from 'types/user.type';
import { CampaignInfo } from 'types/auth.type';

export interface AuthState {
  authenticated: boolean;
  user?: User;
  token?: string;
  firebaseToken?: string;
  currentEmail?: string;
  userExists?: boolean;
  campaignInfo?: CampaignInfo;
  emailSent?: boolean;
  resetLoginData?: () => void;
  status?: 'FETCHING' | 'AUTHENTICATED' | 'NOT AUTHENTICATED';
}

const initialState: AuthState = {
  authenticated: false,
  campaignInfo: {
    utm_campaign: '',
    utm_medium: '',
    utm_source: '',
    utm_content: '',
    browserUserId: '',
    user_agent: '',
    source: '',
  },
  status: 'FETCHING',
};

type Action =
  | { type: 'SET_PROPERTIES'; data: Partial<AuthState> }
  | { type: 'ADD_USER_ADDRESS'; address: Address }
  | { type: 'UPDATE_USER_ADDRESS'; address: Address }
  | { type: 'REPLACE_USER_ADDRESSES'; addresses: Address[] }
  | { type: 'UPDATE_USER_PAYMENTS'; payments: Payment[] }
  | { type: 'UPDATE_BROWSER_ID'; id: string }
  | { type: 'RESET_LOGIN_DATA' }
  | { type: 'UPDATE_USER_FAVORITES_LIST'; list: number[] }
  | { type: 'RESET_USER_EXISTS' };

function authReducer(draft: AuthState, action: Action) {
  switch (action.type) {
    case 'SET_PROPERTIES':
      draft = Object.assign({}, draft, action.data);
      return draft;
    case 'ADD_USER_ADDRESS':
      if (draft.user) {
        draft.user.addresses = [...draft.user.addresses, action.address];
      }
      return draft;
    case 'UPDATE_USER_ADDRESS':
      if (draft.user) {
        const addressIndex = draft.user.addresses.findIndex(
          item => item.id === action.address.id,
        );

        if (addressIndex > -1) {
          draft.user.addresses[addressIndex] = action.address;
        } else {
          draft.user.addresses = [...draft.user.addresses, action.address];
        }
      }
      return draft;
    case 'REPLACE_USER_ADDRESSES':
      if (draft.user) {
        draft.user.addresses = action.addresses;
      }
      return draft;
    case 'UPDATE_USER_PAYMENTS':
      if (draft.user) {
        draft.user.payments = action.payments || [];
      }
      return draft;
    case 'UPDATE_BROWSER_ID':
      if (draft.campaignInfo) {
        draft.campaignInfo.browserUserId = action.id;
      }
      return draft;
    case 'UPDATE_USER_FAVORITES_LIST':
      draft.user.favoriteList = action.list;
      return draft;
    case 'RESET_USER_EXISTS':
      draft.userExists = false;
      draft.currentEmail = '';
      return draft;
  }
}

const AuthStateContext = createContext<AuthState>(initialState);

const AuthDispatchContext = createContext<Dispatch<Action> | undefined>(
  undefined,
);

export const AuthProvider = (props: {
  value?: typeof initialState;
  children: React.ReactNode;
}) => {
  const { value, children } = props;

  const [state, dispatch] = useImmerReducer(
    authReducer,
    Object.assign({}, initialState, value),
  );

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};

export function useAuthState(): AuthState {
  const context = useContext(AuthStateContext);

  if (context === undefined) {
    throw new Error(`useAuth must be used inside an AuthProvider`);
  }

  return context;
}

export function useAuthDispatch() {
  const context = useContext(AuthDispatchContext);

  if (context === undefined) {
    throw new Error(`useAuth must be used inside an AuthProvider`);
  }

  return context;
}

export const useAuth = (): [AuthState, Dispatch<Action>] => [
  useAuthState(),
  useAuthDispatch(),
];
